import { PageWrapper } from '../components/Containers';
import { H2, H3, Important, List, OrderedList } from '../components/Typo';

export default function Privacy() {

    return (
        <PageWrapper className="py-40">
            <H2>Datenschutzhinweise für die Online-Shopping-Aktion der Kreissparkasse Bautzen zur Sparkassen-Card („Aktion“)</H2>
            <p>
                <Important>Datenschutzerklärung nach der DSGVO </Important>
            </p>

            <div className="mt-40">
                <H3>1. Name und Anschrift des Verantwortlichen gemäß Artikel 4 Abs. 7 DSGVO</H3>

                <p>
                    Der Verantwortliche im Sinne der Datenschutz-Grundverordnung und anderer nationaler Datenschutzgesetze der Mitgliedsstaaten sowie sonstiger datenschutzrechtlicher Bestimmungen ist die:
                </p>

                <p>
                    K+R GmbH<br />
                    Ludwig-Erhard-Straße 6<br />
                    65760 Eschborn<br />
                    Tel.: +49 (0) 6196 930630<br />
                    E-Mail: info@krgroup.de<br />
                    Website: www.krgroup.de
                </p>

                <H3>2. Name und Anschrift des Datenschutzbeauftragten</H3>

                <p>
                    Der Datenschutzbeauftragte des Verantwortlichen ist:<br />
                    <br />
                    Weißmann Datenschutz GmbH <br />
                    RA Benedikt Rudolph<br />
                    Bosenheimer Str. 10 <br />
                    55543 Bad Kreuznach<br />

                    Tel.: +49 (0) 30 2359 92171<br />
                    Fax: +49 (0) 30 2359 92178<br />
                    Datenschutz@kanzlei-weissmann.de
                </p>

                <H3>3. Allgemeines zur Datenverarbeitung</H3>

                <p>
                    <Important>Umfang der Verarbeitung personenbezogener Daten </Important><br />

                    Wir verarbeiten personenbezogene Daten unserer Nutzer grundsätzlich nur, soweit dies zur Bereitstellung einer funktionsfähigen Website sowie unserer Inhalte und Leistungen erforderlich ist.<br />
                    Die Verarbeitung personenbezogener Daten unserer Nutzer erfolgt regelmäßig nur nach Einwilligung des Nutzers.<br />
                    Eine Ausnahme gilt in solchen Fällen, in denen eine vorherige Einholung einer Einwilligung aus tatsächlichen Gründen nicht möglich ist und die Verarbeitung der Daten durch gesetzliche Vorschriften gestattet ist.<br />
                    <br />
                    <Important>Rechtsgrundlage für die Verarbeitung personenbezogener Daten</Important><br />
                    Soweit wir für Verarbeitungsvorgänge personenbezogener Daten eine Einwilligung der betroffenen Person einholen, dient Art. 6 Abs. 1 lit. a EU-Datenschutzgrundverordnung (DSGVO) als Rechtsgrundlage für die Verarbeitung personenbezogener Daten. 
                    <br /><br />
                    Bei der Verarbeitung personenbezogener Daten, die zur Erfüllung eines Vertrags, dessen Vertragspartei die betroffene Person ist, erforderlich ist, dient Art. 6 Abs. 1 lit. b DSGVO als Rechtsgrundlage. 
                    <br /><br />
                    Dies gilt auch für Verarbeitungsvorgänge, die zur Durchführung vorvertraglicher Maßnahmen erforderlich sind. 
                    <br /><br />
                    Soweit eine Verarbeitung personenbezogener Daten zur Erfüllung einer rechtlichen Verpflichtung erforderlich ist, der unser Unternehmen unterliegt, dient Art. 6 Abs. 1 lit. c DSGVO als Rechtsgrundlage. 
                    <br /><br />
                    Für den Fall, dass lebenswichtige Interessen der betroffenen Person oder einer anderen natürlichen Person eine Verarbeitung personenbezogener Daten erforderlich machen, dient Art. 6 Abs. 1 lit. d DSGVO als Rechtsgrundlage. 
                    <br /><br />
                    Ist die Verarbeitung zur Wahrung eines berechtigten Interesses unseres Unternehmens oder eines Dritten erforderlich und überwiegen die Interessen, Grundrechte und Grundfreiheiten des Betroffenen das erstgenannte Interesse nicht, so dient Art. 6 Abs. 1 lit. f DSGVO als Rechtsgrundlage für die Verarbeitung.
                    <br /><br />
                    <Important>Datenlöschung und Speicherdauer</Important><br />
                    Die personenbezogenen Daten der betroffenen Person werden gelöscht oder gesperrt, sobald der Zweck der Speicherung entfällt. 
                    <br /><br />
                    Eine Speicherung kann darüber hinaus dann erfolgen, wenn dies durch den europäischen oder nationalen Gesetzgeber in unionsrechtlichen Verordnungen, Gesetzen oder sonstigen Vorschriften, denen der Verantwortliche unterliegt, vorgesehen wurde. Eine Sperrung oder Löschung der Daten erfolgt auch dann, wenn eine durch die genannten Normen vorgeschriebene Speicherfrist abläuft, es sei denn, dass eine Erforderlichkeit zur weiteren Speicherung der Daten für einen Vertragsabschluss oder eine Vertragserfüllung besteht.
                </p>

                <H3>4. Bereitstellung der Website und Erstellung von Logfiles</H3>

                <p>
                    <Important>Beschreibung und Umfang der Datenverarbeitung</Important><br />

                    Bei jedem Aufruf unserer Internetseite erfasst der Webserver automatisiert Daten und Informationen vom Computersystem des aufrufenden Rechners.
                    <br /><br />
                    Folgende Daten werden hierbei erhoben:<br />
                    <List>
                        <li>Die IP-Adresse des Nutzers</li>
                        <li>Datum und Uhrzeit des Zugriffs</li>
                        <li>Zeitzonendifferenz zur Greenwich Mean Time (GMT)</li>
                        <li>Inhalte der Anforderung (konkrete Seite)</li>
                        <li>Zugriffsstatus/HTTP-Statuscode</li>
                    </List>
                    <br />
                    Die Daten werden ebenfalls in den Logfiles unseres Systems gespeichert. Eine Speicherung dieser Daten zusammen mit anderen personenbezogenen Daten des Nutzers findet nicht statt.
                    <br /><br />
                    <Important>Rechtsgrundlage für die Datenverarbeitung</Important><br />
                    Rechtsgrundlage für die vorübergehende Speicherung der Daten und der Logfiles ist Art. 6 Abs. 1 lit. f DSGVO.
                    <br /><br />
                    <Important>Zweck der Datenverarbeitung</Important><br />
                    Die vorübergehende Speicherung der IP-Adresse durch das System ist notwendig, um eine Auslieferung der Website an den Rechner des Nutzers zu ermöglichen. Hierfür muss die IP-Adresse des Nutzers für die Dauer der Sitzung gespeichert bleiben. Die Speicherung in Logfiles erfolgt, um die Funktionsfähigkeit der Website sicherzustellen. Zudem dienen uns die Daten zur Optimierung der Website und zur Sicherstellung der Sicherheit unserer informationstechnischen Systeme. Eine Auswertung der Daten zu Marketingzwecken findet in diesem Zusammenhang nicht statt.
                    <br /><br />
                    In diesen Zwecken liegt auch unser berechtigtes Interesse an der Datenverarbeitung nach Art. 6 Abs. 1 lit. f DSGVO.
                    <br /><br />
                    <Important>Dauer der Speicherung</Important><br />
                    Die Daten werden gelöscht, sobald sie für die Erreichung des Zweckes ihrer Erhebung nicht mehr erforderlich sind. Im Falle der Erfassung der Daten zur Bereitstellung der Website ist dies der Fall, wenn die jeweilige Sitzung beendet ist. 
                    <br /><br />
                    Im Falle der Speicherung der Daten in Logfiles ist dies nach spätestens sieben Tagen der Fall. Eine darüber hinausgehende Speicherung ist möglich. In diesem Fall werden die IP-Adressen der Nutzer gelöscht oder verfremdet, sodass eine Zuordnung des aufrufenden Clients nicht mehr möglich ist.
                    <br /><br />
                    <Important>Widerspruchs- und Beseitigungsmöglichkeit</Important><br />
                    Die Erfassung der Daten zur Bereitstellung der Website und die Speicherung der Daten in Logfiles sind für den Betrieb der Internetseite zwingend erforderlich. Es besteht folglich seitens des Nutzers keine Widerspruchsmöglichkeit.
                    <br /><br />
                </p>

                <H3>5. Verwendung von Cookies</H3>

                <p>
                    <Important>a) Beschreibung und Umfang der Datenverarbeitung </Important><br />
                    Unsere Webseite verwendet Cookies. Bei Cookies handelt es sich um Textdateien, die im Internetbrowser bzw. vom Internetbrowser auf dem Computersystem des Nutzers gespeichert werden. Ruft ein Nutzer eine Website auf, so kann ein Cookie auf dem Betriebssystem des Nutzers gespeichert werden. Dieser Cookie enthält eine charakteristische Zeichenfolge, die eine eindeutige Identifizierung des Browsers beim erneuten Aufrufen der Website ermöglicht.
                    <br /><br />
                    Wir setzen funktionale Cookies ein, um die Funktion unserer Website sicherzustellen. Einige Elemente unserer Internetseite erfordern es, dass der aufrufende Browser auch nach einem Seitenwechsel identifiziert werden kann.
                    <br /><br />
                    In den Cookies werden dabei folgende Daten gespeichert und übermittelt:<br />
                    <List>
                        <li>
                            Datum und Uhrzeit eines Abrufs
                        </li>
                        <li>
                            Anonyme Session-ID
                        </li>
                    </List>
                    <br /><br />

                    <Important>b) Rechtsgrundlage für die Datenverarbeitung</Important><br />
                    Die Rechtsgrundlage für die Verarbeitung personenbezogener Daten unter Verwendung von Cookies ist Art. 6 Abs. 1 lit. f DSGVO.
                    <br /><br />

                    <Important>c) Zweck der Datenverarbeitung</Important><br />
                    Der Zweck der Verwendung technisch notwendiger Cookies ist, die Nutzung von Websites für die Nutzer zu ermöglichen. Einige Funktionen unserer Internetseite können ohne den Einsatz von Cookies nicht angeboten werden. Dafür ist es erforderlich, dass der Browser auch nach einem Seitenwechsel wiedererkannt wird. Die durch technisch notwendige Cookies erhobenen Nutzerdaten werden nicht zur Erstellung von Nutzerprofilen verwendet.
                    <br /><br />
                    In diesen Zwecken liegt auch unser berechtigtes Interesse in der Verarbeitung der personenbezogenen Daten nach Art. 6 Abs. 1 lit. f DSGVO
                    <br /><br />

                    <Important>d) Dauer der Speicherung, Widerspruchs- und Beseitigungsmöglichkeit</Important><br />
                    Die Erfassung der Daten zur Bereitstellung der Website und die Speicherung der Daten in Logfiles sind für den Betrieb der Internetseite zwingend erforderlich. Es besteht folglich seitens des Nutzers keine Widerspruchsmöglichkeit.
                </p>

                <H3>6. Abruf der Zalando-Gutscheine </H3>

                <p>
                    Beschreibung und Umfang der Datenverarbeitung
                    <br /><br />
                    Auf unserer Internetseite bieten wir Sparkassen-Card Inhabern die Möglichkeit, bei Verfügbarkeit, einen 50 € Zalando-Gutschein abzurufen. Der Nutzer hat im Vorfeld postalisch einen Flyer, gemeinsam mit seiner neuen Sparkassen-Card, erhalten. In diesem Flyer ist eine Aktionsnummer aufgeführt, mit der unter www.mc-ksk-bautzen.de einer von 1.200 Zalando-Gutscheinen abgerufen werden kann. 
                    <br /><br />
                    Die Aktionsnummer wird dabei in eine Eingabemaske eingegeben und an uns übermittelt und gespeichert. Sofern ein Zalando-Gutscheincode verfügbar ist, wird dieser wiederum dem Nutzer angezeigt und im Backend der Aktionsnummer zugeteilt.  
                    <br /><br />
                    Darüber hinaus, werden keine weitere Daten vom Nutzer aktiv abgefragt.
                    <br /><br />
                    Im Zeitpunkt des Abrufs des Zalando-Gutscheins werden zudem folgende Daten gespeichert:
                    <br /><br />
                    <OrderedList>
                        <li>
                            Nutzungsdaten: Zeitpunkt, Tag, ausgeführte Interaktion, eingegebene Aktionsnummer 
                        </li>
                        <li>
                            Nutzerdaten: IP-Adresse des Nutzers
                        </li>
                    </OrderedList>
                    <br /><br />
                    <Important>Rechtsgrundlage für die Datenverarbeitung</Important><br />
                    Rechtsgrundlage für die Verarbeitung der Daten ist bei Vorliegen einer Einwilligung des Nutzers Art. 6 Abs. 1 lit. a DSGVO.
                    <br /><br />
                    Durch die Teilnahme an der Zalando-Gutschein-Aktion erklärt der Nutzer seine Einwilligung zur oben dargestellten Verarbeitung dieser Daten.
                    <br /><br />
                    <Important>Zweck der Datenverarbeitung</Important><br />
                    Eine Eingabe der Aktionsnummer des Nutzers ist für den Abruf eines Zalando-Gutscheincodes erforderlich. Die Aktionsnummer wird dem Nutzer im Vorfeld randomisiert zugeteilt, es findet keine Speicherung oder Zuordnung von Kundendaten in Verbindung mit der Aktionsnummer statt.
                    <br /><br />
                    Im Hintergrund werden Aktionsnummer und eingelöste Gutschein-Nummer für weitere Auswertmöglichkeiten miteinander verknüpft und gespeichert, dies ist zwingend erforderlich, um den Betrieb der Seite und Aussteuerung von Gutschein-Codes zu gewährleisten. 
                    <br /><br />
                    <Important>Weitergabe der Daten an Dritte</Important><br />
                    Die erhobenen personenbezogenen Daten werden im Rahmen der Ermittlung und Benachrichtigung sowie zum Zweck der Gutschein-Anzeige ggf. an folgende beteiligte Unternehmen weitergegeben:
                    <List>
                        <li>Pluspol Interactive</li>
                        <li>Sonstige Dienstleister</li>
                    </List>
                    <br />
                    Wir verpflichten diese Dienstleister vertraglich dazu, personenbezogene Daten ausschließlich gemäß unseren Anweisungen zu verarbeiten und soweit dies zur Erbringung von Dienstleistungen oder zur Einhaltung rechtlicher Vorschriften erforderlich ist. Wir verpflichten sie darüber hinaus dazu, die Sicherheit und Vertraulichkeit der in unserem Auftrag verarbeiteten personenbezogenen Daten zu gewährleisten, indem geeignete technische und organisatorische Sicherungsmaßnahmen getroffen werden und Mitarbeiter mit Zugang zu personenbezogenen Daten zu Geheimhaltung verpflichtet sind.
                    <br /><br />
                    <Important>Dauer der Speicherung</Important><br />
                    Die Daten werden gelöscht, sobald sie für die Erreichung des Zweckes ihrer Erhebung nicht mehr erforderlich sind, spätestens nach 3 Monaten.
                    <br /><br />
                    <Important>Widerspruchs- und Beseitigungsmöglichkeit</Important><br />
                    Die Erfassung der oben genannten Daten sind für den Betrieb der Internetseite zwingend erforderlich. Es besteht folglich seitens des Nutzers keine Widerspruchsmöglichkeit.
                </p>

                <H3>7. Rechte der betroffenen Person</H3>

                <p>
                    Werden personenbezogene Daten von Ihnen verarbeitet, sind Sie Betroffener i. S. d. DSGVO und es stehen Ihnen folgende Rechte gegenüber dem Verantwortlichen zu:
                    <br /><br />
                    <Important>Auskunftsrecht</Important><br />
                    Sie können von dem Verantwortlichen eine Bestätigung darüber verlangen, ob personenbezogene Daten, die Sie betreffen, von uns verarbeitet werden.
                    <br /><br />
                    Liegt eine solche Verarbeitung vor, können Sie von dem Verantwortlichen über folgende Informationen Auskunft verlangen:
                    <OrderedList>
                        <li>
                            die Zwecke, zu denen die personenbezogenen Daten verarbeitet werden; 
                        </li>
                        <li>
                            die Kategorien der personenbezogenen Daten, die verarbeitet werden;
                        </li>
                        <li>
                            die Empfänger bzw. die Kategorien der Empfänger, gegenüber denen die Sie betreffenden personenbezogenen Daten offengelegt wurden oder noch offengelegt werden;
                        </li>
                        <li>
                            die geplante Dauer der Speicherung der Sie betreffenden personenbezogenen Daten oder, falls konkrete Angaben hierzu nicht möglich sind, Kriterien für die Festlegung der Speicherdauer;
                        </li>
                        <li>
                            das Bestehen eines Rechts auf Berichtigung oder Löschung der Sie betreffenden personenbezogenen Daten, eines Rechts auf Einschränkung der Verarbeitung durch den Verantwortlichen oder eines Widerspruchsrechts gegen diese Verarbeitung;
                        </li>
                        <li>
                            das Bestehen eines Beschwerderechts bei einer Aufsichtsbehörde;
                        </li>
                        <li>
                            alle verfügbaren Informationen über die Herkunft der Daten, wenn die personenbezogenen Daten nicht bei der betroffenen Person erhoben werden;
                        </li>
                        <li>
                            das Bestehen einer automatisierten Entscheidungsfindung einschließlich Profiling gemäß Art. 22 Abs. 1 und 4 DSGVO und – zumindest in diesen Fällen – aussagekräftige Informationen über die involvierte Logik sowie die Tragweite und die angestrebten Auswirkungen einer derartigen Verarbeitung für die betroffene Person.
                        </li>
                    </OrderedList>

                    Ihnen steht das Recht zu, Auskunft darüber zu verlangen, ob die Sie betreffenden personenbezogenen Daten in ein Drittland oder an eine internationale Organisation übermittelt werden. In diesem Zusammenhang können Sie verlangen, über die geeigneten Garantien gem. Art. 46 DSGVO im Zusammenhang mit der Übermittlung unterrichtet zu werden.
                    <br /><br />
                
                    <Important>Recht auf Berichtigung</Important><br />
                    Sie haben ein Recht auf Berichtigung und/oder Vervollständigung gegenüber dem Verantwortlichen, sofern die verarbeiteten personenbezogenen Daten, die Sie betreffen, unrichtig oder unvollständig sind. Der Verantwortliche hat die Berichtigung unverzüglich vorzunehmen.
                    <br /><br />
                    <Important>Recht auf Einschränkung der Verarbeitung</Important><br />
                    Unter den folgenden Voraussetzungen können Sie die Einschränkung der Verarbeitung der Sie betreffenden personenbezogenen Daten verlangen:
                    <OrderedList>
                        <li>
                            wenn Sie die Richtigkeit, der Sie betreffenden personenbezogenen für eine Dauer bestreiten, die es dem Verantwortlichen ermöglicht, die Richtigkeit der personenbezogenen Daten zu überprüfen;
                        </li>
                        <li>
                            die Verarbeitung unrechtmäßig ist und Sie die Löschung der personenbezogenen Daten ablehnen und stattdessen die Einschränkung der Nutzung der personenbezogenen Daten verlangen;
                        </li>
                        <li>
                            der Verantwortliche die personenbezogenen Daten für die Zwecke der Verarbeitung nicht länger benötigt, Sie diese jedoch zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen benötigen, oder
                        </li>
                        <li>
                            wenn Sie Widerspruch gegen die Verarbeitung gemäß Art. 21 Abs. 1 DSGVO eingelegt haben und noch nicht feststeht, ob die berechtigten Gründe des Verantwortlichen gegenüber Ihren Gründen überwiegen.
                        </li>
                    </OrderedList>
                    Wurde die Verarbeitung der Sie betreffenden personenbezogenen Daten eingeschränkt, dürfen diese Daten – von ihrer Speicherung abgesehen – nur mit Ihrer Einwilligung oder zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen oder zum Schutz der Rechte einer anderen natürlichen oder juristischen Person oder aus Gründen eines wichtigen öffentlichen Interesses der Union oder eines Mitgliedstaats verarbeitet werden.
                    <br /><br />
                    Wurde die Einschränkung der Verarbeitung nach den o. g. Voraussetzungen eingeschränkt, werden Sie von dem Verantwortlichen unterrichtet, bevor die Einschränkung aufgehoben wird.
                    <br /><br />
                    <Important>Recht auf Löschung</Important><br />
                    <Important>a) Löschungspflicht</Important><br />
                    Sie können von dem Verantwortlichen verlangen, dass die Sie betreffenden personenbezogenen Daten unverzüglich gelöscht werden, und der Verantwortliche ist verpflichtet, diese Daten unverzüglich zu löschen, sofern einer der folgenden Gründe zutrifft:
                    <OrderedList>
                        <li>
                            Die Sie betreffenden personenbezogenen Daten sind für die Zwecke, für die sie erhoben oder auf sonstige Weise verarbeitet wurden, nicht mehr notwendig.
                        </li>
                        <li>
                            Sie widerrufen Ihre Einwilligung, auf die sich die Verarbeitung gem. Art. 6 Abs. 1 lit. a oder Art. 9 Abs. 2 lit. a DSGVO stützte, und es fehlt an einer anderweitigen Rechtsgrundlage für die Verarbeitung.
                        </li>
                        <li>
                            Sie legen gem. Art. 21 Abs. 1 DSGVO Widerspruch gegen die Verarbeitung ein und es liegen keine vorrangigen berechtigten Gründe für die Verarbeitung vor, oder Sie legen gem. Art. 21 Abs. 2 DSGVO Widerspruch gegen die Verarbeitung ein.
                        </li>
                        <li>
                            Die Sie betreffenden personenbezogenen Daten wurden unrechtmäßig verarbeitet.
                        </li>
                        <li>
                            Die Löschung der Sie betreffenden personenbezogenen Daten ist zur Erfüllung einer rechtlichen Verpflichtung nach dem Unionsrecht oder dem Recht der Mitgliedstaaten erforderlich, dem der Verantwortliche unterliegt.
                        </li>
                        <li>
                            Die Sie betreffenden personenbezogenen Daten wurden in Bezug auf angebotene Dienste der Informationsgesellschaft gemäß Art. 8 Abs. 1 DSGVO erhoben.
                        </li>
                    </OrderedList>
                    <Important>b) Information an Dritte</Important><br />
                    Hat der Verantwortliche die Sie betreffenden personenbezogenen Daten öffentlich gemacht und ist er gem. Art. 17 Abs. 1 DSGVO zu deren Löschung verpflichtet, so trifft er unter Berücksichtigung der verfügbaren Technologie und der Implementierungskosten angemessene Maßnahmen, auch technischer Art, um für die Datenverarbeitung Verantwortliche, die die personenbezogenen Daten verarbeiten, darüber zu informieren, dass Sie als betroffene Person von ihnen die Löschung aller Links zu diesen personenbezogenen Daten oder von Kopien oder Replikationen dieser personenbezogenen Daten verlangt haben.
                    <br /><br />
                    <Important>c) Ausnahmen</Important><br />
                    Das Recht auf Löschung besteht nicht, soweit die Verarbeitung erforderlich ist
                    <OrderedList>
                        <li>
                            zur Ausübung des Rechts auf freie Meinungsäußerung und Information;
                        </li>
                        <li>
                            zur Erfüllung einer rechtlichen Verpflichtung, die die Verarbeitung nach dem Recht der Union oder der Mitgliedstaaten, dem der Verantwortliche unterliegt, erfordert, oder zur Wahrnehmung einer Aufgabe, die im öffentlichen Interesse liegt oder in Ausübung öffentlicher Gewalt erfolgt, die dem Verantwortlichen übertragen wurde;
                        </li>
                        <li>
                            aus Gründen des öffentlichen Interesses im Bereich der öffentlichen Gesundheit gemäß Art. 9 Abs. 2 lit. h und i sowie Art. 9 Abs. 3 DSGVO;
                        </li>
                        <li>
                            für im öffentlichen Interesse liegende Archivzwecke, wissenschaftliche oder historische Forschungszwecke oder für statistische Zwecke gem. Art. 89 Abs. 1 DSGVO, soweit das unter Abschnitt a) genannte Recht voraussichtlich die Verwirklichung der Ziele dieser Verarbeitung unmöglich macht oder ernsthaft beeinträchtigt oder
                        </li>
                        <li>
                            zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen.
                        </li>
                    </OrderedList>
                    <Important>Recht auf Unterrichtung</Important><br />
                    Haben Sie das Recht auf Berichtigung, Löschung oder Einschränkung der Verarbeitung gegenüber dem Verantwortlichen geltend gemacht, ist dieser verpflichtet, allen Empfängern, denen die Sie betreffenden personenbezogenen Daten offengelegt wurden, diese Berichtigung oder Löschung der Daten oder Einschränkung der Verarbeitung mitzuteilen, es sei denn, dies erweist sich als unmöglich oder ist mit einem unverhältnismäßigen Aufwand verbunden.
                    <br /><br />
                    Ihnen steht gegenüber dem Verantwortlichen das Recht zu, über diese Empfänger unterrichtet zu werden.
                    <br /><br />
                    <Important>Recht auf Datenübertragbarkeit</Important><br />
                    Sie haben das Recht, die Sie betreffenden personenbezogenen Daten, die Sie dem Verantwortlichen bereitgestellt haben, in einem strukturierten, gängigen und maschinenlesbaren Format zu erhalten. Außerdem haben Sie das Recht, diese Daten einem anderen Verantwortlichen ohne Behinderung durch den Verantwortlichen, dem die personenbezogenen Daten bereitgestellt wurden, zu übermitteln, sofern 
                    <OrderedList>
                        <li>
                            die Verarbeitung auf einer Einwilligung gem. Art. 6 Abs. 1 lit. a DSGVO oder Art. 9 Abs. 2 lit. a DSGVO oder auf einem Vertrag gem. Art. 6 Abs. 1 lit. b DSGVO beruht und
                        </li>
                        <li>
                            die Verarbeitung mithilfe automatisierter Verfahren erfolgt.
                        </li>
                    </OrderedList>
                    In Ausübung dieses Rechts haben Sie ferner das Recht, zu erwirken, dass die Sie betreffenden personenbezogenen Daten direkt von einem Verantwortlichen einem anderen Verantwortlichen übermittelt werden, soweit dies technisch machbar ist. Freiheiten und Rechte anderer Personen dürfen hierdurch nicht beeinträchtigt werden.
                    <br /><br />
                    Das Recht auf Datenübertragbarkeit gilt nicht für eine Verarbeitung personenbezogener Daten, die für die Wahrnehmung einer Aufgabe erforderlich ist, die im öffentlichen Interesse liegt oder in Ausübung öffentlicher Gewalt erfolgt, die dem Verantwortlichen übertragen wurde.
                    <br /><br />
                    <Important>Widerspruchsrecht</Important><br />
                    Sie haben das Recht, aus Gründen, die sich aus Ihrer besonderen Situation ergeben, jederzeit gegen die Verarbeitung der Sie betreffenden personenbezogenen Daten, die aufgrund von Art. 6 Abs. 1 lit. e oder f DSGVO erfolgt, Widerspruch einzulegen; dies gilt auch für ein auf diese Bestimmungen gestütztes Profiling.
                    <br /><br />
                    Der Verantwortliche verarbeitet die Sie betreffenden personenbezogenen Daten nicht mehr, es sei denn, er kann zwingende schutzwürdige Gründe für die Verarbeitung nachweisen, die Ihre Interessen, Rechte und Freiheiten überwiegen, oder die Verarbeitung dient der Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen.
                    <br /><br />
                    Werden die Sie betreffenden personenbezogenen Daten verarbeitet, um Direktwerbung zu betreiben, haben Sie das Recht, jederzeit Widerspruch gegen die Verarbeitung der Sie betreffenden personenbezogenen Daten zum Zwecke derartiger Werbung einzulegen; dies gilt auch für das Profiling, soweit es mit solcher Direktwerbung in Verbindung steht.
                    <br /><br />
                    Widersprechen Sie der Verarbeitung für Zwecke der Direktwerbung, so werden die Sie betreffenden personenbezogenen Daten nicht mehr für diese Zwecke verarbeitet.
                    <br /><br />
                    Sie haben die Möglichkeit, im Zusammenhang mit der Nutzung von Diensten der Informationsgesellschaft – ungeachtet der Richtlinie 2002/58/EG – Ihr Widerspruchsrecht mittels automatisierter Verfahren auszuüben, bei denen technische Spezifikationen verwendet werden.
                    <br /><br />
                    <Important>Recht auf Widerruf der datenschutzrechtlichen Einwilligungserklärung</Important><br />
                    Sie haben das Recht, Ihre datenschutzrechtliche Einwilligungserklärung jederzeit zu widerrufen. Durch den Widerruf der Einwilligung wird die Rechtmäßigkeit der aufgrund der Einwilligung bis zum Widerruf erfolgten Verarbeitung nicht berührt.
                    <br /><br />
                    <Important>Automatisierte Entscheidung im Einzelfall einschließlich Profiling</Important><br />
                    Sie haben das Recht, nicht einer ausschließlich auf einer automatisierten Verarbeitung – einschließlich Profiling – beruhenden Entscheidung unterworfen zu werden, die Ihnen gegenüber rechtliche Wirkung entfaltet oder Sie in ähnlicher Weise erheblich beeinträchtigt. Dies gilt nicht, wenn die Entscheidung
                    <OrderedList>
                        <li>
                            für den Abschluss oder die Erfüllung eines Vertrags zwischen Ihnen und dem Verantwortlichen erforderlich ist,
                        </li>
                        <li>
                            aufgrund von Rechtsvorschriften der Union oder der Mitgliedstaaten, denen der Verantwortliche unterliegt, zulässig ist und diese Rechtsvorschriften angemessene Maßnahmen zur Wahrung Ihrer Rechte und Freiheiten sowie Ihrer berechtigten Interessen enthalten oder
                        </li>
                        <li>
                            mit Ihrer ausdrücklichen Einwilligung erfolgt.
                        </li>
                    </OrderedList>
                    Allerdings dürfen diese Entscheidungen nicht auf besonderen Kategorien personenbezogener Daten nach Art. 9 Abs. 1 DSGVO beruhen, sofern nicht Art. 9 Abs. 2 lit. a oder g gilt und angemessene Maßnahmen zum Schutz der Rechte und Freiheiten sowie Ihrer berechtigten Interessen getroffen wurden.
                    <br /><br />
                    Hinsichtlich der in (1) und (3) genannten Fälle trifft der Verantwortliche angemessene Maßnahmen, um die Rechte und Freiheiten sowie Ihre berechtigten Interessen zu wahren, wozu mindestens das Recht auf Erwirkung des Eingreifens einer Person seitens des Verantwortlichen auf Darlegung des eigenen Standpunkts und auf Anfechtung der Entscheidung gehört.
                    <br /><br />
                    <Important>Recht auf Beschwerde bei einer Aufsichtsbehörde</Important><br />
                    Unbeschadet eines anderweitigen verwaltungsrechtlichen oder gerichtlichen Rechtsbehelfs steht Ihnen das Recht auf Beschwerde bei einer Aufsichtsbehörde, insbesondere in dem Mitgliedstaat ihres Aufenthaltsorts, ihres Arbeitsplatzes oder des Orts des mutmaßlichen Verstoßes, zu, wenn Sie der Ansicht sind, dass die Verarbeitung der Sie betreffenden personenbezogenen Daten gegen die DSGVO verstößt.
                    <br /><br />
                    Die Aufsichtsbehörde, bei der die Beschwerde eingereicht wurde, unterrichtet den Beschwerdeführer über den Stand und die Ergebnisse der Beschwerde einschließlich der Möglichkeit eines gerichtlichen Rechtsbehelfs nach Art. 78 DSGVO.
                    <br /><br />
                    Stand: September 2022
                </p>
            </div>
        </PageWrapper>
    )
}