import { PageWrapper } from "../components/Containers";
import { H2, OrderedList } from "../components/Typo";

export default function Terms() {

    return (
        <PageWrapper className="py-40">
            <H2>Aktionsbedingungen
                <br /><br />
                Kreissparkasse Bautzen:
                <br />
                Zalando-Gutschein-Aktion für Sparkassen-Card Inhaber
            </H2>

            <div className="mt-40">
                <OrderedList>
                    <li>
                        Die Teilnahme an der Aktion richtet sich nach den nachstehend aufgeführten Bedingungen. Die Aktion wird durchgeführt von der K+R GmbH, Ludwig-Erhard-Straße 6, 65760 Eschborn („K+R“), im Auftrag der Kreissparkasse Bautzen.
                    </li>

                    <li>
                        Die Aktion wird in der Zeit vom 25. November 2022, 00:00 Uhr, bis 31. Januar 2023, 23:59 Uhr, (Aktionszeitraum) durchgeführt.
                    </li>

                    <li>
                        Sparkassen Kunden, die postalisch eine neue Sparkassen-Card (Debitkarte) zugestellt bekommen, erhalten zusätzlich einen Beileger (Flyer), der eine individuelle Aktionsnummer beinhaltet. Diese Aktionsnummer kann auf der Aktionsseite www.mc-ksk-bautzen.de gegen einen 50 € Zalando-Gutscheincode eingelöst werden, solange der Vorrat reicht. Die individuelle Aktionsnummer ist bis zum 31.01.2023 gültig und verfällt, wenn sie nicht innerhalb dieses Zeitraums auf der Aktionsseite in einen Zalando-Gutscheincode umgewandelt wird.
                    </li>

                    <li>
                        Es stehen 1.200 Zalando-Gutscheincodes zur Verfügung. Sollten diese aufgebraucht sein, wird eine entsprechende Information auf der Aktionsseite angezeigt. Ein Anspruch auf Ersatz oder einen alternativen Gewinn besteht nicht.
                    </li>

                    <li>
                        Voraussetzung für den Erhalt des 50 € Zalando-Gutscheincodes ist die Registrierung der Sparkassen-Card für das S-ID-Check-Verfahren. Dafür sind das Herunterladen der S-ID-Check-App im App-Store bzw. Google-Play-Store auf das mobile Endgerät sowie die Registrierung der Karte in der S-ID-Check-App erforderlich. Die Registrierung der Karte muss vor Teilnahme an der Aktion vollständig erfolgt sein. Weitere Informationen zum S-ID-Check-Verfahren finden Sie <a href='https://www.ksk-bautzen.de/de/home/service/s-id-check-mastercard.html'>hier</a>.
                    </li>

                    <li>
                        Der 50 € Zalando-Gutscheincode ist bis zum 30.06.2023 zur einmaligen Einlösung auf der Zalando-Homepage sowie in der Zalando App Deutschland gültig. Nicht gültig für die Zalando-Lounge und Zalon. Umtausch, Barauszahlung oder nachträgliche Anrechnung auf eine Bestellung sind ausgeschlossen. Ein Mindestbestellwert besteht nicht. Es gelten die <a href='https://www.zalando.de/zalando-agb/'>Allgemeinen Geschäftsbedingungen</a> von Zalando.
                    </li>

                    <li>
                        Die Veräußerung (auch im Rahmen von Online-Auktionen) sowie eine Barauszahlung des Gutscheins sind ausgeschlossen.
                    </li>

                    <li>
                        Teilnahmeberechtigt ist jede Inhaberin/jeder Inhaber einer neuen Sparkassen-Card der Kreissparkasse Bautzen (Co-Badge Debit Mastercard®), der/die mit seiner/ihrer neuen Karte einen Aktionsbeileger erhalten und zum Zeitpunkt der Teilnahme das 18. Lebensjahr vollendet hat. Ferner ist die abgeschlossene Registrierung der neuen Karte in der S-ID-Check-App (siehe Ziffer 5) Voraussetzung für die Teilnahme an der Aktion. Ausgeschlossen von der Teilnahme an der Aktion sind Mitarbeiter der Sparkasse, der K+R GmbH und Mastercard sowie deren Angehörige und von der Kreissparkasse Bautzen, K+R bzw. Mastercard beauftragte Personen, Unternehmen und Dienstleister, einschließlich deren Mitarbeiter und Angehörige.
                    </li>

                    <li>
                        Die Teilnahme an der Aktion ist freiwillig.
                    </li>

                    <li>
                        Die Haftung von K+R ist im Falle von Vorsatz und grober Fahrlässigkeit unbeschränkt, im Übrigen ausgeschlossen. Dies gilt nicht für die Verletzung wesentlicher Vertragspflichten und die Verletzung von Leib, Leben und Gesundheit.
                    </li>

                    <li>
                        Fragen in Zusammenhang mit der Aktion sind an K+R GmbH, Ludwig-Erhard-Straße 6, 65760 Eschborn (aktion@mc-ksk-bautzen.de) zu richten.
                    </li>

                    <li>
                        Der Rechtsweg ist ausgeschlossen.
                    </li>

                    <li>
                        Es gilt ausschließlich das Recht der Bundesrepublik Deutschland.
                    </li>

                    <li>
                        Der Veranstalter behält sich das Recht vor, die Aktion jederzeit zu ändern und/oder zu beenden, sofern dies aus tatsächlichen oder rechtlichen Gründen erforderlich sein sollte. Auf bereits erfolgte Gutschein-Einlösungen hat dies keine Auswirkungen.
                    </li>

                    <li>
                        Sollten einzelne Bestimmungen dieser Bedingungen unwirksam sein, so hat dies keine Auswirkungen auf die übrigen Bestimmungen.
                    </li>
                </OrderedList>
            </div>
        </PageWrapper>
    )
}
