import styled from 'styled-components';
import { Media } from '../lib/theme';
import IdCheckTeaser from '../media/images/teaser.jpg';
import CorrectTeaser from '../media/images/correct-teaser.jpg';
import { Button } from './Button';
import { BaseContainer, GridContainer, Col } from './Containers';
import { SectionTitle, Important, List, OrderedList } from './Typo';

const IdCheckContainer = styled(BaseContainer)`
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media ${Media.LG} {
        padding-right: 75px;
    }
`;

const StyledImg = styled.img`
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover
`;
export const AdditionalHelp = ({isCodeValid}: {isCodeValid: boolean}) => {
    if (isCodeValid) {
        return (
            <>
                <SectionTitle>Jetzt 50 € bei Zalando sparen!</SectionTitle>

                <GridContainer>
                    <Col span={5} className='relative'>
                        <StyledImg src={isCodeValid ? CorrectTeaser : IdCheckTeaser} />
                    </Col>

                    <Col span={7}>
                        <IdCheckContainer>
                            <div>
                                <p className='mt-0'>Hinterlegen Sie gleich Ihre Sparkassen-Card bei Zalando, um zukünftig noch bequemer zu zahlen, und lösen Sie Ihren Gutschein im Bestellprozess ein.</p>
                                <Important className='mb-10'>
                                    So funktioniert's:
                                </Important>

                                <OrderedList>
                                    <li>Auf Zalando.de anmelden und Wunschartikel in den Warenkorb legen.</li>
                                    <li>Unter Zahlungsart &gt; Kredit-/Debitkarte die Daten Ihrer Sparkassen-Card eingeben und bei „Diese Angaben für zukünftige Eingaben merken“ ein Häkchen setzen.</li>
                                    <li>Den hier abgerufenen Zalando-Gutschein-Code im Bestellprozess eingeben – fertig!</li>
                                </OrderedList>
                            </div>

                            <Button
                                as='a'
                                href='https://www.zalando.de'
                                target='_blank'
                                className='mt-20'
                            >
                                Hier geht's zu Zalando
                            </Button>
                        </IdCheckContainer>
                    </Col>
                </GridContainer>
            </>
        );
    }
    else {
        return (
            <>
                <SectionTitle>Bereit fürs Bezahlen im Internet: Ihre Sparkassen-Card</SectionTitle>

                <GridContainer>
                    <Col span={5} className='relative'>
                        <StyledImg src={IdCheckTeaser} />
                    </Col>

                    <Col span={7}>
                        <IdCheckContainer>
                            <div>
                                <List>
                                    <li>Höchste Sicherheit beim Bezahlen im Internet – dank Mastercard<sup>®</sup> Identity Check<sup>™</sup></li>
                                    <li>Bequemes Bestätigen der eigenen Identität – mit nur einem Klick in der „S-ID-Check“ App</li>
                                    <li>Weltweiter Einsatz – auch ohne Datenverbindung mit der App</li>
                                </List>
                            </div>

                            <Button
                                as='a'
                                href='https://www.ksk-bautzen.de/de/home/service/s-id-check-mastercard.html'
                                target='_blank'
                                className='mt-20'
                            >
                                Laden Sie jetzt die S-ID-Check-App herunter und registrieren Sie Ihre Karte für sicheres
                                Online-Bezahlen
                            </Button>
                        </IdCheckContainer>
                    </Col>
                </GridContainer>
            </>
        );
    }
};
