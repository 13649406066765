/* eslint-disable react/jsx-no-target-blank */
import styled from 'styled-components';
import { BaseContainer, PageWrapper } from '../components/Containers';
import { Hero } from '../components/Hero';
import { Important } from '../components/Typo';
import { ReactComponent as McIcon } from '../media/icons/mastercard.svg';
import { Media } from '../lib/theme';
import { useEffect, useState } from 'react';
import { AdditionalHelp } from '../components/AdditionalHelp';
import { CodeRedeemer } from '../components/CodeRedeemer';
import { trackInternal } from '../lib/trackInternal';

const HintContainer = styled(BaseContainer)`
    display: flex;
    margin-top: 20px;
    margin-bottom: 20px;
`

const StyledMastercardIcon = styled(McIcon)`
    display: none;
    width: 90px;
    flex-shrink: 0;
    margin: 0 15px 0 70px;

    @media ${Media.MD} {
        display: block;
    }
`

export default function Index() {
    useEffect(() => {
        trackInternal('visit');
    }, []);

    const [isCodeValid, setIsCodeValid] = useState(false);

    return (
        <>
            <Hero />

            <PageWrapper>
                <CodeRedeemer isCodeValid={isCodeValid} setIsCodeValid={setIsCodeValid} />
                
                <HintContainer>
                    <div>
                        <Important primary>Wichtig:</Important> In vielen Online-Shops finden Sie nur den Begriff
                        „Kreditkarte“ für die Kartenzahlung. Wählen Sie diese Zahlungsart, denn Sie können problemlos
                        mit Ihrer Sparkassen-Card weltweit überall dort zahlen, wo Mastercard<sup>®</sup> akzeptiert
                        wird!
                    </div>

                    <StyledMastercardIcon />
                </HintContainer>
                
                <AdditionalHelp isCodeValid={isCodeValid} />
                
            </PageWrapper>
        </>
    );
}

