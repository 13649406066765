export const Colors = {
    primary: '#ff0000',
    secondary: '#039863'
}

export const Fonts = {
    normal: 'Sparkasse,Helvetica,Arial,sans-serif',
    bold: 'SparkasseMedium,Helvetica,Arial,sans-serif'
}

export const Media = {
    MD: '(min-width: 768px)',
    LG: '(min-width: 1080px)',
}