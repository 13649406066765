import styled from "styled-components";
import { Button } from "./Button";
import { BaseContainer, GridContainer, Col } from "./Containers";
import { TextInput } from "./TextInput";
import { H2, Important } from "./Typo";
import zalandoLogo from '../media/images/zalando.png';
import { FormEvent, useEffect, useState } from "react";
import { publicUrl } from "../lib/publicUrl";
import { trackInternal } from "../lib/trackInternal";

const InstructionCol = styled(Col)`
    display: flex;
    flex-direction: column;
`;

const StyledForm = styled.form`
    display: flex;
`;

const Hint = styled.div`
    p {
        margin: 0 0 10px;
        font-size: 11px;
        line-height: 1.2;
    }

    > p {
        padding: 0 0 0 6px;
    }
    @media(max-width: 767px){
        padding-top: 10px;
    }
`;

const NoLeftContainer = styled(BaseContainer)`
    margin-bottom: 20px;
`;

export const CodeRedeemer = ({ isCodeValid, setIsCodeValid }: {isCodeValid: boolean, setIsCodeValid: (is: boolean)=> void}) => {
    const [redemptionCode, setRedemptionCode] = useState('');
    const [voucherCode, setVoucherCode] = useState('');
    const [errorType, setErrorType] = useState<'NONE'|'VALIDATION' | 'UNKNOWN' | 'NO-LEFT'>('NONE');
    const [codeHasVoucher, setCodeHasVoucher] = useState(false);
    const [isTouched, setIsTouched] = useState(false);
    const [redemptionDate, setRedemptionDate] = useState('');

    interface jsonResponse {
        result: 'success' | 'already' | 'no-left';
        voucher: '' | string;
        redeemedAt?: string;
    }

    useEffect(() => {
        if (isTouched) {
            trackInternal('fill-code');
        }
    }, [isTouched]);

    function handleOnChange(e: React.ChangeEvent<HTMLInputElement>) {
        setRedemptionCode(e.target.value.toUpperCase());
        setIsTouched(true);
    }

    async function handleSubmit(e: FormEvent) {
        e.preventDefault();

        if (redemptionCode === '' || !/^[A-Z0-9]*$/.test(redemptionCode)) {
            setErrorType('VALIDATION');
            return;
        }
        const participant = {
            redemptionCode,
        };
        const body = new FormData();
        body.append('participant', JSON.stringify(participant));

        const rawResponse = await fetch(`${publicUrl}/api/v1/participant/add`, { body, method: 'POST'});

        const jsonResponse:jsonResponse = await rawResponse.json();

        switch (jsonResponse.result) {
            case 'already':
                handleResponseResult(true, jsonResponse.voucher, jsonResponse.redeemedAt);
                break;
            case 'success':
                handleResponseResult(false, jsonResponse.voucher);
                break;
            case 'no-left':
                setErrorType('NO-LEFT');
                setIsCodeValid(false);
                break;
            default:
                setErrorType('UNKNOWN');
                setIsCodeValid(false);
                break;
        }
    }

    function handleResponseResult(hasCodeVoucher: boolean, voucher: string, date?: string) {
        if (voucher === '') {
            setErrorType('VALIDATION');
            return;
        }
        if (date) {
            setRedemptionDate(date);
        }
        setIsCodeValid(true);
        setCodeHasVoucher(hasCodeVoucher);
        setVoucherCode(voucher);
        return;
    }

    async function loadPdf(e: FormEvent) {
        e.preventDefault();
        window.location.assign(`${publicUrl}/api/v1/pdf/${redemptionCode}`);
    }

    return (
        <>
            <NoLeftContainer>
                <H2>Diesmal hat es leider nicht geklappt.</H2>
                <p>
                    Es tut uns leid, alle verfügbaren Gutschein-Codes wurden bereits abgerufen. Wir wünschen Ihnen
                    dennoch viel Freude beim sicheren Online-Shopping mit Ihrer Sparkassen-Card.{' '}
                </p>
            </NoLeftContainer>
            { errorType !== 'NO-LEFT' && (
                <GridContainer>
                    <InstructionCol span={5}>
                        <BaseContainer className='flex-1'>
                            <p>
                                Kürzlich haben Sie Ihre neue Sparkassen-Card<sup>1</sup> erhalten.
                            </p>

                            <p>
                                <Important>Registrieren Sie Ihre Karte gleich in der S-ID-Check-App!</Important> Denn
                                damit ist Ihre Sparkassen-Card das perfekte Zahlungsmittel für sichere Online-Einkäufe.
                            </p>

                            <p>
                                Schon registriert? Dann erwartet Sie hier als Willkommensgeschenk ein 50 €
                                Zalando-Gutschein, solange der Vorrat reicht.
                            </p>

                            <img src={zalandoLogo} height='30' className='mt-15' alt='Zalando Logo' />
                        </BaseContainer>
                    </InstructionCol>

                    <Col span={7}>
                        <BaseContainer className='full-height flex-c-sb'>
                            <div>
                                <H2>{isCodeValid ? 'Herzlichen Glückwunsch!' : 'Zalando-Gutschein abrufen'}</H2>
                                {isCodeValid ? (
                                    <p>
                                        Freuen Sie sich jetzt über Ihren 50 € Zalando-Gutschein!
                                        <br />
                                        Ihr Gutschein-Code<sup>2</sup> lautet:
                                    </p>
                                ) : (
                                    <p>
                                        Bitte geben Sie hier Ihre persönliche Aktionsnummer² aus dem Flyer ein, den Sie
                                        gemeinsam mit Ihrer neuen Karte erhalten haben:
                                    </p>
                                )}
                                <StyledForm>
                                    <TextInput
                                        id='customer-code'
                                        name='customer-code'
                                        type='text'
                                        placeholder='Aktionsnummer eingeben'
                                        value={isCodeValid ? voucherCode : redemptionCode}
                                        onChange={handleOnChange}
                                        isCodeValid={isCodeValid}
                                        readOnly={isCodeValid ? true : false}
                                    />
                                    {isCodeValid ? (
                                        <Button onClick={loadPdf}>Gutschein als PDF herunterladen</Button>
                                    ) : (
                                        <Button onClick={handleSubmit}>Gutschein abrufen</Button>
                                    )}
                                </StyledForm>
                                {isCodeValid ? (
                                    <div>
                                        {codeHasVoucher && (
                                            <p>
                                                <>
                                                    <Important>Hinweis:</Important> Diese Aktionsnummer wurde bereits am{' '}{redemptionDate}{' '}
                                                    eingegeben. Ihr Zalando-Gutschein steht Ihnen hier weiterhin bis 31.01.2023 zum
                                                    Abruf zur Verfügung und ist einmalig auf{' '}
                                                    <a target={'_blank'} rel='noreferrer' href='https://www.zalando.de/'>www.zalando.de</a> einlösbar.
                                                </>
                                            </p>
                                        )}
                                        <p>
                                            <Important primary>Tipp:</Important> Machen Sie alternativ einen Screenshot
                                            oder notieren Sie sich Ihren Gutschein-Code.
                                        </p>
                                    </div>
                                ) : (
                                    <div>
                                        {(errorType === 'UNKNOWN' || errorType === 'VALIDATION') && (
                                            <div>
                                                <p>
                                                    <Important primary>
                                                        Dieser Code existiert nicht. Bitte überprüfen Sie Ihre Eingabe.
                                                    </Important>
                                                </p>
                                                <p>
                                                    <Important>Hinweis:</Important> Hat sich vielleicht ein
                                                    Tippfehler eingeschlichen?
                                                </p>
                                            </div>
                                        )}
                                    </div>
                                )}
                            </div>
                            <Hint>
                                <p>
                                    <sup>1)</sup> Sofern im Text von Sparkassen-Card die Rede ist, handelt es sich um
                                    eine Debitkarte.
                                </p>
                                {isCodeValid ? (
                                    <p>
                                        <sup>2)</sup> Ihr Zalando-Gutschein ist gültig bis 30.06.2023 zur Einlösung auf
                                        der Zalando-Homepage sowie in der Zalando App Deutschland. Nicht gültig für die
                                        Zalando-Lounge und Zalon. Umtausch, Barauszahlung oder nachträgliche Anrechnung
                                        auf eine Bestellung sind ausgeschlossen. Ein Mindestbestellwert besteht nicht.
                                    </p>
                                ) : (
                                    <p>
                                        <sup>2)</sup> Aktionsnummer berechtigt bis 31.01.2023 zum Abruf eines von 1.200
                                        Zalando-Gutscheinen à 50 €, solange der Vorrat reicht. Voraussetzung ist die
                                        abgeschlossene Registrierung der Sparkassen-Card in der S-ID-Check-App.
                                    </p>
                                )}
                            </Hint>
                        </BaseContainer>
                    </Col>
                </GridContainer>
            )}
        </>
    );
}
