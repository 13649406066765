import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Colors, Media } from '../lib/theme';
import Logo from '../media/images/spb-logo-desktop.svg';

const Header = styled.header`
    height: 60px;
    background: ${Colors.primary};

    @media ${Media.LG} {
        height: 96px;
    }

    img {
        height: 100%;
    }

    a {
        text-decoration: none;
    }
`

export const AppHeader = () => {

    return (
        <Header>
            <Link to='/'>
                <img src={Logo} alt='Sparkasse Bautzen' />
            </Link>
        </Header>
    );
}