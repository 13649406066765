import styled from 'styled-components';
import {Colors, Fonts} from '../lib/theme';

type TextInputProps = {
    id: string;
    name: string,
    type: string,
    placeholder: string,
    value: string,
    onChange: (e:React.ChangeEvent<HTMLInputElement>) => void,
    isCodeValid: boolean,
    readOnly: boolean,
}

const BaseTextInput = styled.input<{isCodeValid?: boolean}>`
    padding: 10px 20px 9px;
    font-size: 15px;
    line-height: 18px;
    border-style: solid;
    border-width: 1px;
    border-color: ${props => props.isCodeValid  ? Colors.secondary : '#999'};
    border-radius: 5px;
    text-decoration: none;
    flex-grow: 2;
    margin-right: 20px;
    font-family: ${Fonts};
    font-weight: ${props => props.isCodeValid && 'bold'};
    text-align: ${props => props.isCodeValid && 'center'};

    &:focus {
        outline: ${props => props.isCodeValid && 'none'};
    }
`;

export const TextInput = ({ ...props}:TextInputProps) => {
    return <BaseTextInput {...props}  />;
};