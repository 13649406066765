import { Route, Routes, useLocation } from 'react-router-dom';
import PageIndex from '../pages/Index';
import PageImprint from '../pages/Imprint';
import PagePrivacy from '../pages/Privacy';
import PageTerms from '../pages/Terms';
import Page404 from '../pages/404';
import PageDone from '../pages/Done';
import { AppHeader } from './AppHeader';
import { AppFooter } from './AppFooter';
import { imprintRoute, privacyRoute, termsRoute } from '../lib/routes';
import { useEffect, useState } from 'react';
import { publicUrl } from '../lib/publicUrl';

export const AppLayout = () => {
    const location = useLocation();
    const [currentPhase, setCurrentPhase] = useState<'over' | 'participation' | null>(null);

    useEffect(() => {
        if (currentPhase === null) {
            (async () => {
                const rawResponse = await fetch(`${publicUrl}/api/v1/config`);
                if (!rawResponse.ok) {
                    return;
                }

                const res = await rawResponse.json();

                setCurrentPhase(res.config.phase);
            })()
        }

    }, [currentPhase])

    useEffect(() => {
        window.scrollTo(0,0);
    }, [location.pathname])

    return (
        <>
            <AppHeader />

            <Routes>
                {currentPhase === 'over' ?
                    <>
                        <Route path='/' element={<PageDone />} />
                    </>
                :
                    <>
                        <Route path='/' element={<PageIndex />} />
                    </>
                }
                <Route path={imprintRoute()} element={<PageImprint />} />
                <Route path={termsRoute()} element={<PageTerms />} />
                <Route path={privacyRoute()} element={<PagePrivacy />} />
                <Route path='*' element={<Page404 />} />
            </Routes>

            <AppFooter />
        </>
    )
}
