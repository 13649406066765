import { PageWrapper } from "../components/Containers";
import { H2, Important } from "../components/Typo";

export default function Imprint() {

    return (
        <PageWrapper className='py-40'>
            <H2>Impressum</H2>
            <p>
                <Important>Informationen gemäß § 5 TMG</Important>
            </p>
            <div className='mt-40'>
                <Important>K+R GmbH</Important> (im Auftrag der Kreissparkasse Bautzen)
                <br />
                Ludwig-Erhard-Straße 6<br />
                65760 Eschborn
                <br />
                <br />
                <Important>Vertreten durch die Geschäftsführer:</Important>
                <br />
                Michael Roschanski
                <br />
                Andreas M. Denk <br />
                Simone Zwecker
                <br />
                Miriam Hiekisch <br />
                <br />
                <Important>Kontakt:</Important>
                <br />
                Telefon: +496196 93063-74
                <br />
                Telefax: +496196 93063-45
                <br />
                E-Mail: info@krgroup.de
                <br />
                <br />
                <Important>Registergericht:</Important>
                <br />
                Amtsgericht Frankfurt am Main
                <br />
                Registernummer: HRB 40140
                <br />
                <br />
                <Important>Umsatzsteuer-Identifikationsnummer:</Important>
                <br />
                Umsatzsteuer-Identifikationsnummer gemäß § 27a Umsatzsteuergesetz: DE161720540
            </div>
        </PageWrapper>
    );
}